<template>
  <div class="width-100 height-100 max_box">
    <audio src="audio/90s.mp3" ref="bgAudio" preload="auto"></audio>
    <!-- <div class="info_box">       -->
      <component
        :is="currentView"
        :tempAduioArr="tempAduioArr"
        :cardData ="cardData"
        :secData = "secData"
        ref="coms"
        @playEnd="playNext()"
      ></component>
    <!-- </div> -->
    <!-- <video
      class="robot_video none"
      src="video/test.webm"
      muted
      width="20%"
      height="100%"
      ref="robotVideo"
    ></video> -->
  </div>
</template>
<script>
// import { connectObsCore,sendCommand } from "@/utils/obs.js";
import { initRecordController, startRecord, stopRecord } from "dt-record-controller";
import 'script-loader!createjs/builds/1.0.0/createjs.min.js';
import $ from "jquery";
import { GetParams2 } from "../../utils/common";
import ApiServe from "@/api/index";
import { sleep } from "@/utils/common";
import axios from "axios";
import dumbImagePreloader from "dumb-image-preloader";
import page1 from "./page1.vue";
import page2 from "./page2.vue";
import page3 from "./page3.vue";
import page4 from "./page4.vue";
import page5 from "./page5.vue";
import page6 from "./page6.vue";
import page7 from "./page7.vue";
import page8 from "./page8.vue";
import page9 from "./page9.vue";
import page10 from "./page10.vue";
import page11 from "./page11.vue";
import page12 from "./page12.vue";
import page13 from "./page13.vue";
import page14 from "./page14.vue";
import * as pagadata from "./data";


export default {
  name: "Robot90s",
  components: {
    page1,
    page2,
    page3,
    page4,
    page5,
    page6,
    page7,
    page8,
    page9,
    page10,
    page11,
    page12,
    page13,
    page14,
  },
  data() {
    return {
      audioSrcList: [],
      audioTimeList: [],
      device_id: null,
      recordtimeAll: null,
      // device_id:null,
      id: "",
      model_id: 83,
      model_name: "",
      model_category: "",
      url_extra_params: null,
      ip: "",
      port: "",
      offset_x: "",
      offset_y: "",
      width: "",
      height: "",
      source: "",
      currentIndex: 0,
      // pageSortArr: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,14],
      pageSortArr: [],
      pageTimeArr: [], //记录每个页面的时间与旁白时间进行比较
      tempAduioArr: [], //记录接口给的初始旁白data
      aduioData: [], //旁白的数据
      cardData:[],//页面信息
      secData:[],//股票明细
    };
  },
  created() {
    this.id = GetParams2().id || null;
    this.model_id = GetParams2().model_id || 1;
    this.model_name = GetParams2().model_name || null;
    this.model_category = GetParams2().model_category || null;
    this.url_extra_params = GetParams2().url_extra_params || null;
    this.ip = GetParams2().ip || null;
    this.port = GetParams2().port || null;
    this.offset_x = GetParams2().offset_x || null;
    this.offset_y = GetParams2().offset_y || null;
    this.width = GetParams2().width || null;
    this.height = GetParams2().height || null;
    this.source = GetParams2().source || null;
  },
  computed: {
    allReady() {
      const { tempAduioArr } = this;
      const { cardData } = this;
      return {
        tempAduioArr,cardData,
      };
    },
    newIpPort() {
      if (this.source == "ali") {
        return "https://recordvideoali.wedengta.com";
      } else {
        return "https://recordvideo.wedengta.com";
      }
    },
    currentView() {
      let currentPage = this.pageSortArr[this.currentIndex];
      if (currentPage == 1) {
        return page1;
      }
      if (currentPage == 2) {
        return page2;
      }
      if (currentPage == 3) {
        return page3;
      }
      if (currentPage == 4) {
        return page4;
      }
      if (currentPage == 5) {
        return page5;
      }
      if (currentPage == 6) {
        return page6;
      }
      if (currentPage == 7) {
        return page7;
      }
      if (currentPage == 8) {
        return page8;
      }
      if (currentPage == 9) {
        return page9;
      }
      if (currentPage == 10) {
        return page10;
      }
      if (currentPage == 11) {
        return page11;
      }
      if (currentPage == 12) {
        return page12;
      }
      if (currentPage == 13) {
        return page13;
      }
      if (currentPage == 14) {
        return page14;
      }
      return page1;
    },
  },
  watch: {
    allReady(val) {
      if (this.tempAduioArr.length==14 && this.cardData.length ==14) {
          startRecord();
          this.startPlay();
        // if(this.$route.query.isRecord!=undefined){
        // //连接obs
        //   // this.startRecord();
          
        //   startRecord();
        //   this.startPlay();
        // }else if(this.ip && this.port) {
        //   const params = {
        //     ip: this.ip,
        //     port: this.port,
        //     offset_x: this.offset_x,
        //     offset_y: this.offset_y,
        //     width: this.width,
        //     height: this.height,
        //   };

        //   ApiServe.recordstartPc(this.newIpPort, params)
        //     .then(({ code }) => {
        //       this.startPlay();
        //     })
        //     .catch((err) => {
        //       ApiServe.recordreleasePc(this.newIpPort, {
        //         ip: this.ip,
        //         port: this.port,
        //       });
        //       this.startPlay();
        //     });
        //   }else {
        //     this.startPlay();
        //   }    
      }
    },
  },
  mounted() {
    //获取数据    
    this.getPage(GetParams2());
    initRecordController();

    // if(this.$route.query.isRecord!=undefined){
    //   //连接obs
    // // this.connectObs();
    // initRecordController();
    // }


    },
  methods: {
    //obs控制
    async connectObs() {
      if (this.$route.query.port != undefined) {
        this.port = this.$route.query.port;
      }
      connectObsCore(this.port)
    },
    //开始录像
    async startRecord() {
      console.log("StartRecording");
      await sendCommand("StartRecording");
    },
    // 停止录像
    async stopRecord() {
      console.log("StopRecording");
      await sendCommand("StopRecording");
    },
    //获取页面和股票信息
    async getPage(options){
      const pagenum = options.page; 
      let sec_info = JSON.parse(options.url_extra_params)
      //获取股票名称，为了和旁白对应上
      let sec_name = await this.ajaSecName(JSON.parse(options.url_extra_params).dt_stock_code)
      sec_name=sec_name.data.data[0].SecuAbbr
      if (options.url_extra_params){
        await this.secData.push({"sec_code":JSON.parse(options.url_extra_params).dt_stock_code,"sec_name":sec_name})
      }

      await this.getAudio();
      await this.getdata(this.secData[0]);
      

      //财务安全 排除为0
      let financialScore = this.cardData[11][0].score
      //员工分析 排除返回值为空 staffinfo为0
      let staffinfo = this.cardData[2].length
      // 确定页面(排除财务分数为0的页面，排除没有员工分析的页面)
      let statusyingli = this.cardData[8][0].status //盈利能力
      let statuschangzhai = this.cardData[9][0].status //偿债能力
      let statuschengzhang = this.cardData[10][0].status //偿债能力      
      let statusCapital = this.cardData[7][0].status //股东人数         
      let mainBus = this.cardData[5].length //主营业务
      let executiveInfo = this.cardData[1].length //公司高管
      let orInfo = this.cardData[3].length //营业收入
      let ntInfo = this.cardData[4].length //净利润
      if(typeof(pagenum) != "undefined"){
          this.pageSortArr.push(parseInt(pagenum))
      } else if(typeof(pagenum) == "undefined"){
        this.pageSortArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,14]
      }
      if(financialScore == 0){
        this.pageSortArr.splice(12,1);        
        // this.tempAduioArr.splice(12,1);
      }
      
      if(statuschengzhang == 0){              
        // this.tempAduioArr.splice(3,1);
        this.pageSortArr.splice(11,1)
      }
      
      if(statuschangzhai == 0){              
        // this.tempAduioArr.splice(3,1);
        this.pageSortArr.splice(10,1)
      }
      
      if(statusyingli == 0){              
        // this.tempAduioArr.splice(3,1);
        this.pageSortArr.splice(9,1)
      }
      
      if(statusCapital == 0){              
        // this.tempAduioArr.splice(3,1);
        this.pageSortArr.splice(8,1)
      }
      if(mainBus == 0){              
        // this.tempAduioArr.splice(3,1);
        this.pageSortArr.splice(6,1)
      }
      if(ntInfo == 0){              
        // this.tempAduioArr.splice(3,1);
        this.pageSortArr.splice(5,1)
      }
      if(orInfo == 0){              
        // this.tempAduioArr.splice(3,1);
        this.pageSortArr.splice(4,1)
      }
      if(staffinfo == 0){              
        // this.tempAduioArr.splice(3,1);
        this.pageSortArr.splice(3,1)
      }
      if(executiveInfo == 1 || executiveInfo == 0){              
        // this.tempAduioArr.splice(3,1);
        this.pageSortArr.splice(2,1)
      }
      
      
    
    console.log(this.tempAduioArr);
    console.log(this.pageSortArr);

    },
    // 获取数据
    async getdata(options){
      let Data =[]
     
      // await pagadata.Page2Data(this.newIpPort,options)
      //   .then((res) => {
      //     Data.push(res);
      //   })
      //   .catch((err) => {
      //     if (this.ip) {
      //       ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
      //     }
      //   });
      // await pagadata.Page3Data(options)
      //   .then((res) => {
      //     Data.push(res);
      //   })
      //   .catch((err) => {
      //     if (this.ip) {
      //       ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
      //     }
      //   });
      // await pagadata.Page4Data(this.newIpPort,options)
      //   .then((res) => {
      //     Data.push(res);
      //   })
      //   .catch((err) => {
      //     if (this.ip) {
      //       ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
      //     }
      //   });
      // await pagadata.Page5Data(options)
      //   .then((res) => {
      //     Data.push(res);
      //   })
      //   .catch((err) => {
      //     if (this.ip) {
      //       ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
      //     }
      //   });
      // await pagadata.Page6Data(options)
      //   .then((res) => {
      //     Data.push(res);
      //   })
      //   .catch((err) => {
      //     if (this.ip) {
      //       ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
      //     }
      //   });
      // await pagadata.Page7Data(options)
      //   .then((res) => {
      //     Data.push(res);
      //   })
      //   .catch((err) => {
      //     if (this.ip) {
      //       ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
      //     }
      //   });
      // await pagadata.Page8Data(options)
      //   .then((res) => {
      //     Data.push(res);
      //   })
      //   .catch((err) => {
      //     if (this.ip) {
      //       ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
      //     }
      //   });
      // await pagadata.Page9Data(options)
      //   .then((res) => {
      //     Data.push(res);
      //   })
      //   .catch((err) => {
      //     if (this.ip) {
      //       ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
      //     }
      //   });
      // await pagadata.Page10Data(this.newIpPort,options)
      //   .then((res) => {
      //     Data.push(res);
      //   })
      //   .catch((err) => {
      //     if (this.ip) {
      //       ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
      //     }
      //   });
      // await pagadata.Page11Data(this.newIpPort,options)
      //   .then((res) => {
      //     Data.push(res);
      //   })
      //   .catch((err) => {
      //     if (this.ip) {
      //       ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
      //     }
      //   });
      // await pagadata.Page12Data(this.newIpPort,options)
      //   .then((res) => {
      //     Data.push(res);
      //   })
      //   .catch((err) => {
      //     if (this.ip) {
      //       ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
      //     }
      //   });
      // await pagadata.Page13Data(this.newIpPort,options)
      //   .then((res) => {
      //     Data.push(res);
      //   })
      //   .catch((err) => {
      //     if (this.ip) {
      //       ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
      //     }
      //   });
      // await pagadata.Page1Data()
      //   .then((res) => {
      //     Data.push(res);
      //   })
      //   .catch((err) => {
      //     if (this.ip) {
      //       ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
      //     }
      //   });
      // await pagadata.Page14Data()
      //   .then((res) => {
      //     Data.push(res);
      //   })
      //   .catch((err) => {
      //     if (this.ip) {
      //       ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
      //     }
        // });
      this.cardData.push(await pagadata.Page2Data(this.newIpPort,options));
      this.cardData.push(await pagadata.Page3Data(options));      
      this.cardData.push(await pagadata.Page4Data(this.newIpPort,options));
      this.cardData.push(await pagadata.Page5Data(options));
      this.cardData.push(await pagadata.Page6Data(options));
      this.cardData.push(await pagadata.Page7Data(options));
      this.cardData.push(await pagadata.Page8Data(options));
      this.cardData.push(await pagadata.Page9Data(options));
      this.cardData.push(await pagadata.Page10Data(this.newIpPort,options));
      this.cardData.push(await pagadata.Page11Data(this.newIpPort,options));
      this.cardData.push(await pagadata.Page12Data(this.newIpPort,options));
      this.cardData.push(await pagadata.Page13Data(this.newIpPort,options));
      this.cardData.push(await pagadata.Page1Data()); 
      this.cardData.push(await pagadata.Page14Data());
      //预加载图片
      let imageList = [];
      let pageSrc
      pageSrc = this.cardData[0][2]
      for(var key in pageSrc){
        imageList.push(pageSrc[key])

      }
      await dumbImagePreloader(imageList);

      let videoList =[];
      let videoSrc1 = this.cardData[12][0].videoSrc
      let videoSrc2 = this.cardData[13][0].videoSrc
      videoList.push({'src':videoSrc1})
      videoList.push({'src':videoSrc2})
      this.tempAduioArr.map((item,index)=>{
        videoList.push(
          {"src":item.audio_link}
        )}
      )
      console.log(videoList);
      await this.monitorSideVoiceLoaded(videoList);
      // this.cardData = Data;
    },

    // 获取旁白
    async getAudio() {
      let params = {
        model_id: 86,
      };6
      if (this.url_extra_params) {
        params = {
          model_id: 86,
          url_extra_params: encodeURIComponent(this.url_extra_params),
        };
      }
      ApiServe.getVideoDataPC(params)
        .then(({ data, code }) => {
          if (code == 200) {
            this.tempAduioArr = data;
          }
        })
        .catch((err) => {
          if (this.ip) {
            ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
          }
        });
    },

    // 股票名称
    async ajaSecName(options){
      return await axios.get("https://dyvideotest.wedengta.com/api/secName?sec_code="+options)
    },
    
        //监控加载完成
    async monitorSideVoiceLoaded(file) {
      var preload = new createjs.LoadQueue(true);
                  //注意加载音频文件需要调用如下代码行
            preload.installPlugin(createjs.SOUND);
            //为preloaded添加当队列完成全部加载后触发事件
            preload.addEventListener("complete",()=>{});
            //设置最大并发连接数  最大值为10
            preload.setMaxConnections(3);
            preload.loadManifest(file);
    },

    setAudioData(pageTimeArr) {
      //  页面与旁白必须一对一
      let result = [];
      let timeIndex = 0;
      let len = pageTimeArr.length;
      for (let i = 0; i < len; i++) {
        let { audio_link, pageLen } = pageTimeArr[i];
        result.push({
          audioLink: audio_link,
          timeIndex: timeIndex,
          audioLen: pageLen,
        });
        //下一个起始需要加上总时长
        timeIndex += pageTimeArr[i].pageLen;
      }
      return result;
    },
    endRecord() {
      stopRecord();
      // if(this.$route.query.isRecord!=undefined){
      // //连接obs
      // stopRecord();
      //   // this.stopRecord();
      // }else {
      //   this.aduioData = this.setAudioData(this.pageTimeArr);

      //   let totalTime = 1;
      //   this.pageTimeArr.map((item) => {
      //     totalTime += item.pageLen;
      //   });
      //   let filterList = this.aduioData.filter((item) => item.audioLink != "");

      //   const data = {
      //     id: this.id,
      //     model_id: 83,
      //     videoTotalTime: totalTime,
      //     videmBgm: "https://res.idtcdn.com/Cube/Voices/90s.mp3",
      //     audiosOrderList: filterList,
      //   };
      //   ApiServe.recordstopPc(this.newIpPort, { ip: this.ip, port: this.port })
      //     .then(() => {
      //       ApiServe.combineaudioswithbgm(
      //         this.newIpPort,
      //         this.ip,
      //         this.port,
      //         data
      //       )
      //         .then(({ data }) => {
      //           // data.task_id = this.id;
      //           // // 更新视频记录
      //           // ApiServe.updateVideoInfo(data);
      //           ApiServe.recordreleasePc(this.newIpPort, {
      //             ip: this.ip,
      //             port: this.port,
      //           });
      //         })
      //         .catch(() => {
      //           ApiServe.recordreleasePc(this.newIpPort, {
      //             ip: this.ip,
      //             port: this.port,
      //           });
      //         });
      //     })
      //     .catch(() => {
      //       ApiServe.recordreleasePc(this.newIpPort, {
      //         ip: this.ip,
      //         port: this.port,
      //       });
      //     });
      //   }

    },
    // 页面card一个接一个播放
      async startPlay() {
      this.$refs.bgAudio.volume = 0.5
      this.$refs.bgAudio.play();
      await sleep(1000);
      // await sleep(3000);
      this.$nextTick(() => {
        this.$refs.bgAudio.volume = 0.5
        this.$refs.bgAudio.play();             
        // this.playRobotVideo();
        this.$refs.coms.playCardStart();
      });

      //记录第一页的时间
      this.pageTimeArr.push(this.getCurrentPageTime());
    },
    // async playRobotVideo() {
    //   $(".robot_video").removeClass("none");
    //   this.$refs.robotVideo.play();
    // }, 
    async playNext() {
      if (this.currentIndex == this.pageSortArr.length - 1) {
        const pageTime = this.getCurrentPageTime();

        setTimeout(() => {
          this.endRecord();
        }, pageTime.pageLen * 1000);
        return;
      }
      // await sleep(150);
      this.currentIndex++;

      this.$nextTick(() => {
        this.$refs.coms.playCardStart();
        this.pageTimeArr.push(this.getCurrentPageTime());
        
      });
    },
    getCurrentPageTime() {
      let obj = this.$refs.coms.getTime();
      return obj;
    },
  },
};
</script>
<style scoped lang="less">
* {
  box-sizing: border-box;
  li {
    list-style: none;
  }
}
.max_box {
  
  height: 1080px;
  background: url("../../assets/img/90s/bg.png");  
  box-sizing: border-box;
  display: flex;
}
.info_box {
  width:80%;
  height:100%;
}
.none {
  visibility: hidden;
  // display: none;
}
</style>