<template>
  <!-- page5 -->
  <div class="width-100 height-100" id="card1">
    <audio :src="audiosrc" ref="audio" preload="auto"></audio>
    <div class="head">
      <div class="img">
        <img src="../../assets/img/90s/icon.png" alt="" />
      </div>
      <div class="text">财务评级</div>
      <div class="company_name">{{ Page13Data[0].company_name }}</div>
    </div>
    <div class="content none">
      <div class="title none" id="Title">{{ Page13Data[0].secode }}</div>
      <div class="sub_title none" id="subTit">
        <span>财务</span>
        <span>{{ Page13Data[0].riskLevelDesc }}</span>
      </div>
      <div class="rank none">
        <div>{{ Page13Data[0].riskLevel }}</div>
        <div>财务安全评级</div>
      </div>
      <div id="chart1"></div>
      <!-- <div id="num1">56</div> -->
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep } from "@/utils/common";
import { Page13Data } from "./data";
export default {
  name: "TwoComponent",
  data: function () {
    return {
      audiosrc: "",
      Page13Data:[],
    };
  },
  props: ["cardData","secData", "tempAduioArr"],
  mounted() {},
  created() {
    // this.Page10Data=Page10Data
    // this.getdata(this.secData[0]);
  },
  watch: {
        cardData: {
      handler(val) {
        if (val.length > 0) {
          this.Page13Data = val[11];
        }
      },
      
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async getdata(options){
      this.Page13Data = await Page13Data(options);
    },
    playNumVaryIn(id, fixed) {
      this.number = anyElement({
        targets: document.querySelector(id),
        duration: 1200,
        toFixed: fixed == undefined ? 2 : fixed,
        easing: "easeOutQuint",
        inEffect: "numVaryIn",
        outEffect: "fadeOut",
      });
      this.number.goIn.restart();
    },
    playIn(inEffect, outEffect, dom) {
      const _outEffect = outEffect == undefined ? "fadeOut" : outEffect;
      this.txt = null;
      this.txt2 = null;
      this.txt = anyElement({
        targets: document.querySelector(dom),
        isSplitTxt: true,
        duration: 1200,
        delay: 60,
        inEffect: inEffect,
        outEffect: _outEffect,
      });
      this.txt.goIn.restart();
    },
    playOut() {
      this.txt.goOut.restart();
    },
    // 页面card一个接一个播放
    async playCardStart() {
      this.audiosrc = this.tempAduioArr[12].audio_link;
      this.$nextTick(() => {
        this.$refs["audio"].play();
      });

      setTimeout(() => { 
        $("#card1").fadeOut(300);
      }, (this.getTime().pageLen-0.3) * 1000);

      setTimeout(() => { 
        this.$emit("playEnd");
      }, this.getTime().pageLen* 1000);
      
      await this.playPage1();
    }, 
    async outPage(){
      $("#card1").fadeOut(300)
    },
    getTime() {
      // 默认返回5秒
      let time = 7;
      let audioTime = Math.ceil(this.tempAduioArr[12].audio_length);
      if (audioTime > 7) {
        time = audioTime;
      }
      return {
        audio_link: this.tempAduioArr[12].audio_link,
        pageLen: time+0.3,
      };
    },
    async playPage1() {
      $(".head").removeClass("none");
      $(".head").addClass("animate__animated animate__fadeInDownBig");
      await sleep(1000);
      $(".content").removeClass("none");
      $(".content").addClass("animate__animated animate__fadeIn");
      await sleep(1000);
      $(".title").removeClass("none");
      this.playIn("fadeIn", "fadeOut", "#Title");
      await sleep(1000);
      $(".sub_title").removeClass("none");
      $(".sub_title").addClass("animate__animated animate__fadeIn");
      await sleep(1000);
      $(".rank").removeClass("none");
      $(".rank").addClass("animate__animated animate__bounce");
      await sleep(1000);
      this.loadChart1();
    },
    loadChart1() {
      let myChart = this.$echarts.init(document.getElementById("chart1"));
      // 绘制图表
      myChart.setOption({
        title: {
          text: this.Page13Data[0].score,
          textStyle: {
            color: "#BED3FF",
            fontSize: 100,
          },
          left: "center",
          top: "center",
        },
        angleAxis: {
          max: 100, // 满分
          clockwise: true, // 逆时针
          // 隐藏刻度线
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        radiusAxis: {
          type: "category",
          // 隐藏刻度线
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        polar: {
          center: ["50%", "50%"],
          radius: "100%", //图形大小
        },
        series: [
          {
            type: "bar",
            data: [
              {
                value: this.Page13Data[0].score,
                itemStyle: {
                  color: "#3875F4",
                },
              },
            ],
            coordinateSystem: "polar",
            roundCap: true,
            barWidth: 100,
            barGap: "-100%", // 两环重叠
            z: 2,
          },
          {
            // 灰色环
            type: "bar",
            data: [
              {
                value: 100,
                itemStyle: {
                  color: "#45536A",
                  shadowColor: "RGBA(67, 80, 102, 1)",
                  shadowBlur: 5,
                  shadowOffsetY: 2,
                },
              },
            ],
            coordinateSystem: "polar",
            roundCap: true,
            barWidth: 100,
            barGap: "-100%", // 两环重叠
            z: 1,
          },
        ],
      });
    },
  },
};
</script>
<style scoped lang="less">
#card1 {
  // background: url("../../assets/img/90s/bg.png");
  // box-sizing: border-box;
  .head {
    display: flex;
    position: absolute;
    top: 95px;
    left: 69px;
    color: #fff;
    justify-content: space-around;
    height: 60px;
    line-height: 53px;
    .img {
      width: 78px;
      height: 53px;
      margin-right: 34px;
    }
    .text {
      width: 208px;
      height: 52px;
      font-size: 52px;
      font-family: Source Han Sans CN;
      font-weight: 800;
      margin-right: 14px;
    }
    .company_name {
      width: 208px;
      height: 60px;
      background: #3875f4;
      border-radius: 16px 16px 16px 4px;
      font-size: 40px;
      font-weight: 800;
      text-align: center;
    }
  }
  .content {
    position: absolute;
    left: 180px;
    top: 230px;
    width: 1560px;
    height: 730px;
    background: linear-gradient(180deg, #33475d 0%, #11121a 100%);
    box-shadow: 0px 10px 30px rgba(6, 19, 29, 0.6);
    border-radius: 24px;
    .title {
      width: 473px;
      height: 60px;
      font-size: 60px;
      font-family: Source Han Sans CN;
      font-weight: 800;
      color: #ffffff;
      position: absolute;
      top: 104px;
      left: 231px;
    }
    .sub_title {
      position: absolute;
      top: 204px;
      left: 231px;
      span:first-of-type {
        font-size: 40px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
        margin-right: 10px;
      }
      span:nth-of-type(2) {
        background: #3edd8f;
        border-radius: 4px;
        font-size: 36px;
        font-weight: 500;
        color: #ffffff;
        padding: 11px;
        box-sizing: border-box;
      }
    }
    .rank {
      position: absolute;
      top: 368px;
      left: 229px;
      div:first-of-type {
        width: 240px;
        height: 180px;
        line-height: 180px;
        font-size: 180px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #719eff;
        margin-bottom: 16px;
        text-align: center;
      }
      div:nth-of-type(2) {
        font-size: 36px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #abbcde;
        text-align: center;
      }
    }
    #chart1 {
      width: 600px;
      height: 600px;
      position: absolute;
      top: 76px;
      right: 226px;
    }
    #num1 {
      font-size: 84px;
      width: 93px;
      height: 84px;
      color: #bed3ff;
      position: absolute;
      top: 324px;
      right: 483px;
    }
  }
}
</style>

