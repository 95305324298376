import { render, staticRenderFns } from "./page14.vue?vue&type=template&id=9b1d5ccc&scoped=true&"
import script from "./page14.vue?vue&type=script&lang=js&"
export * from "./page14.vue?vue&type=script&lang=js&"
import style0 from "./page14.vue?vue&type=style&index=0&id=9b1d5ccc&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b1d5ccc",
  null
  
)

export default component.exports